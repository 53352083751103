import BasePlugin from '../BasePlugin'

export default class AddIntegrationNumbersForInvestmentExpensesBudgetAndInvestmentBudgetCommand extends BasePlugin {
  async execute () {
    let investmentRequestId = this.context.getModel().id
    this.context.getCard().setLoading(true)

    this.context.$http.post(`${this.context.$config.api}/registryservice/plugins/execute/AddIntegrationNumbersForInvestmentExpensesBudgetAndInvestmentBudgetCommand`,
      { 'investmentRequestId': investmentRequestId },
      {
        headers: {
          'Content-Type': 'application/json'
        }
      }
    ).catch((error) => {
      switch (error.response.data.code) {
        case 'investment_request_serial_number_not_found':
          this.context.$message.error('Номер заявки не найден')
          break
        default:
          this.context.$message.error('Неизвестная ошибка')
          break
      }
    }).then((response) => {
      this.context.getCard().setLoading(false)
    })
  }
}
